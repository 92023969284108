import Konva from 'konva';
import FontFaceObserver from "fontfaceobserver";

function formatNumber(value, format) {
    if (format === 'None') { return value }
    let number = parseFloat(value);
    if (isNaN(number)) {
        throw new Error("Invalid number format");
    }
    const formats = {
        '1 000 000.00': { thousandSeparator: ' ', decimalSeparator: '.', decimals: true },
        '1 000 000,00': { thousandSeparator: ' ', decimalSeparator: ',', decimals: true },
        '1,000,000.00': { thousandSeparator: ',', decimalSeparator: '.', decimals: true },
        '1.000.000,00': { thousandSeparator: '.', decimalSeparator: ',', decimals: true },
        '1 000 000': { thousandSeparator: ' ', decimalSeparator: '', decimals: false },
        '1,000,000': { thousandSeparator: ',', decimalSeparator: '', decimals: false },
        '1.000.000': { thousandSeparator: '.', decimalSeparator: '', decimals: false }
    };
    const formatOptions = formats[format];
    if (!formatOptions) {
        throw new Error("Invalid format specified");
    }
    const fractionDigits = formatOptions.decimals ? 2 : 0;
    return number.toLocaleString('en-US', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
        useGrouping: true
    }).replace(/,/g, 'TEMP')
      .replace(/\./g, formatOptions.decimalSeparator)
      .replace(/TEMP/g, formatOptions.thousandSeparator);
}

export const scaleImage = (image) => {
    let rect = image?.parent?.findOne('.background');
    if (!rect) return;

    const group = image.getParent();
    const mode = image.getAttr('mode');
    const srcWidth = image.getAttr('initWidth');
    const srcHeight = image.getAttr('initHeight');

    let rectWidth = rect.width() * image.parent.scaleX();
    let rectHeight = rect.height() * image.parent.scaleY();
    const extraDimension = group.getAttr('extraDimension');
    if (extraDimension) {
       rectWidth = extraDimension.width * image.parent.scaleX();
       rectHeight = extraDimension.height * image.parent.scaleY();
       group.setAttr('extraDimension', null);
    }
    const strokeWidth =  Math.floor(rect.getAttr('strokeWidth')) || 0;
    const strokePosition = strokeWidth / 2;
    const initialRadius = [0, 0, 0, 0];
    const cornerRadius = rect.getAttr('cornerRadius') || initialRadius;

    rect.size({
        width: rectWidth,
        height: rectHeight
    });

    let hRatio = rectWidth / srcWidth;
    let vRatio = rectHeight / srcHeight;
    const min_ratio = Math.min(hRatio, vRatio);
    const imagePosition = {
      x: ((rectWidth - srcWidth * min_ratio) / 2),
      y: ((rectHeight - srcHeight * min_ratio) / 2),
    };

    const imageSize = {
      width: srcWidth * min_ratio - (strokeWidth),
      height: srcHeight * min_ratio - (strokeWidth),
    };

    if (mode === 'stretch') {
      image.position({
        x: strokeWidth / 2,
        y: strokeWidth / 2,
      });
      const imageWidthStretch = rectWidth - strokeWidth;
      const imageHeightStretch = rectHeight - strokeWidth;

      image.size({
        width: imageWidthStretch > 0 ? imageWidthStretch : 1,
        height: imageHeightStretch > 0 ? imageHeightStretch : 1
      });
    } else {
      image.position({
        x: imagePosition.x + strokePosition ,
        y: imagePosition.y + strokePosition,
      });
      image.size({
        width: imageSize.width > 0 ? imageSize.width : 1,
        height: imageSize.height > 0 ? imageSize.height : 1,
      });

      const dimensionsFloored = {
        imageWidth: Math.floor(image.width()),
        imageHeight: Math.floor(image.height()),
        rectWidth:  Math.floor(rectWidth),
        rectHeight: Math.floor(rectHeight),
      };

      if (mode === 'fit') {
        if (cornerRadius) {
          image.setAttr('cornerRadius', initialRadius);
        }

        const averageRadius = (cornerRadius[0] + cornerRadius[1] + cornerRadius[2] + cornerRadius[3]) / 4;
        if (dimensionsFloored.imageHeight > (dimensionsFloored.rectHeight - strokeWidth) || dimensionsFloored.imageWidth > (dimensionsFloored.rectWidth - strokeWidth - (averageRadius * 2))) {
          image.position({
            x: imagePosition.x + (averageRadius / 2),
            y: imagePosition.y + (averageRadius / 2),
          });
          image.size({
            width: srcWidth * min_ratio - averageRadius,
            height: srcHeight * min_ratio - averageRadius,
          });
        }
      }
    }

    if (mode !== 'fit') {
      if (cornerRadius) {
        image.setAttr('cornerRadius', cornerRadius);
      }
      if (strokeWidth === 0) {
        rect.fillEnabled(false);
      } else {
        rect.fillEnabled(true);
      }
    } else {
      rect.fillEnabled(true);
    }

    if (mode === 'flex') {
      group.setAttr('extraDimension', {
        width:rect.width(),
        height:rect.height(),
      });
      image.position({
        x: imagePosition.x + (strokeWidth / 2),
        y: imagePosition.y + (strokeWidth / 2),
      });
      image.size({
        width: imageSize.width,
        height: imageSize.height,
      });

      rect.size({
        width: imageSize.width + strokeWidth,
        height: imageSize.height + strokeWidth,
      });
      rect.position({
        x: imagePosition.x,
        y: imagePosition.y,
      });
    } else {
      rect.position({
        x: 0,
        y: 0,
      });
    }

    const rectBg = rect.getAttr('fill');

    if (rectBg) {
      rect.setAttr('extraBg', rectBg);
    }

    if (mode === 'scale') {
      image.opacity(0);
      rect.opacity(1);
      if (rectBg) {
        rect.setAttr('extraBg', rectBg);
      }
      rect.fill(null);
    } else {
      const extraBg = rect.getAttr('extraBg');
      if (extraBg) {
        rect.fill(extraBg);
        rect.opacity(1);
      } else {
        rect.opacity(0);
      }
      image.opacity(1);
    }

    let max_ratio = Math.max(hRatio, vRatio);
    rect.fillPatternScale({
        x: max_ratio,
        y: max_ratio
    });
    rect.fillPatternOffset({
        x: (srcWidth - rectWidth / max_ratio) / 2,
        y: (srcHeight - rectHeight / max_ratio) / 2
    });
    image.parent.scale({x: 1, y: 1});
}

export const scaleText = (textObj, res, rej) => {
    let fullText = (textObj.getAttr('fullText') ?? '').toString();

    const isEmptyValue = fullText === '';

    if (textObj.getAttr('forceDoubleDigit') && !isNaN(parseFloat(fullText))) {
        fullText = parseFloat(fullText).toFixed(2).toString();
    }

    if (textObj.getAttr('numberFormat') && !isEmptyValue) {
        fullText = formatNumber(fullText, textObj.getAttr('numberFormat'));
    }


    if (textObj.getAttr('prefix') && !isEmptyValue) {
        fullText = `${textObj.getAttr('prefix')}${fullText}`;
    }

    if (textObj.getAttr('postfix') && !isEmptyValue) {
        fullText = `${fullText}${textObj.getAttr('postfix')}`;
    }

    if (textObj.getAttr('regexpMatch') && textObj.getAttr('regexpReplace')) {
        const regex = createRegex(textObj.getAttr('regexpMatch'));
        if (regex) {
            fullText = fullText.replace(regex, textObj.getAttr('regexpReplace'))
        }
    }

    switch (textObj.getAttr('textFormat')) {
        case 'upperCase':
            fullText = fullText.toUpperCase()
            break
        case 'lowerCase':
            fullText = fullText.toLowerCase()
            break
        case 'withCapital':
            fullText = fullText.charAt(0).toUpperCase() + fullText.slice(1)
            break
        default:
            break
    }

    textObj.text(fullText);

    let txtGroup = textObj.parent,
        substrate = txtGroup.findOne('.substrate'),
        bgRect = txtGroup.findOne('.background'),
        fontObserver = new FontFaceObserver(textObj.fontFamily()),
        width = bgRect.width() * txtGroup.scaleX(),
        height = bgRect.height() * txtGroup.scaleY(),
        vis_text;

    const minFontSize = parseInt(textObj.getAttr('minFontSize')),
        maxFontSize = parseInt(textObj.getAttr('maxFontSize')),
        maxLines = parseInt(textObj.getAttr('maxLines')),
        substrateLeft = parseInt(substrate.getAttr('substrateLeft')),
        substrateRight = parseInt(substrate.getAttr('substrateRight')),
        substrateTop = parseInt(substrate.getAttr('substrateTop')),
        substrateBottom = parseInt(substrate.getAttr('substrateBottom')),
        substrateToggle = substrate.getAttr('substrateToggle'),
        perfectToggle = substrate.getAttr('perfectToggle'),
        align = textObj.align(),
        verticalAlign = textObj.getAttr('verticalAlign');

    return fontObserver.load().then(function () {
        // TODO: рендерит не тот шрифт, но после дрега норм...

        bgRect.width(width);
        bgRect.height(height);

        if (width > 0 && height > 0) {
            if (substrateToggle) {
                if ((substrateLeft + substrateRight) >= width ||
                    (substrateTop + substrateBottom) >= height) {
                    txtGroup.scale({x: 1, y: 1});
                    return
                }
                textObj.width(width - substrateLeft - substrateRight);
                textObj.height(height - substrateTop - substrateBottom);
            } else {
                textObj.width(width);
                textObj.height(height);
            }
        } else {
            txtGroup.scale({x: 1, y: 1});
            return
        }
        textObj.position({x: 0, y: 0});

        if (textObj.getAttr('dynamicField')) {
            textObj.setAttr('fontSize', maxFontSize);
            txtGroup.getLayer().draw();

            vis_text = collectVisibleText(textObj.textArr);

            while (!checkText(vis_text, textObj) && parseInt(textObj.fontSize() / 2) >= minFontSize) {
                textObj.fontSize(parseInt(textObj.fontSize() / 2));
                vis_text = collectVisibleText(textObj.textArr);
            }

            while (checkText(vis_text, textObj) && textObj.fontSize() < maxFontSize) {
                textObj.fontSize(textObj.fontSize() + 1);
                vis_text = collectVisibleText(textObj.textArr);
            }

            while (!checkText(vis_text, textObj) && textObj.fontSize() > minFontSize) {
                textObj.fontSize(textObj.fontSize() - 1);
                vis_text = collectVisibleText(textObj.textArr);
            }

            let realVisText = getRealVisibleTextForLines(textObj);

            if (!checkText(vis_text, textObj)) {
                if (textObj.getAttr('cutText')) {
                    realVisText = realVisText.substring(0, realVisText.length - 3) + '...';
                    textObj.text(realVisText);
                    bgRect.fill(bgRect.getAttr('saved_fill'));
                } else {
                    // TODO: raise if validating
                    bgRect.fill('red');
                }
            } else {
                bgRect.fill(bgRect.getAttr('saved_fill'));
            }
        }

        if (substrateToggle) {

            const textSize = getTextSize(textObj);

            substrate.position({
                x: perfectToggle && align === 'left' ? 0 : textSize.x,
                y: perfectToggle && verticalAlign === 'top' ? 0 : textSize.y
            });

            const substrateWidth = textSize.width + substrateLeft + substrateRight + (
                perfectToggle && align === 'left' ? textSize.x : 0
            );

            const substrateHeight = textSize.height + substrateTop + substrateBottom + (
                perfectToggle && verticalAlign === 'top' ? textSize.y : 0
            );

            substrate.size({
                width: perfectToggle && align === 'right' ? bgRect.width() - substrate.x() : substrateWidth,
                height: perfectToggle && verticalAlign === 'bottom' ? bgRect.height() - substrate.y() : substrateHeight
            });

            textObj.position({x: substrateLeft, y: substrateTop});
        } else {
            substrate.size({width: width, height: height});
            substrate.position({x: 0, y: 0});
        }

        txtGroup.scale({x: 1, y: 1});
        if (res) res()

    }).catch(function (e) {
        console.error('Error loading font', textObj.fontFamily(), e);
        if (rej) {
            rej(e)
        } else {
            throw e;
        }
    });

    function getRealVisibleTextForLines(textObject) {
        let visibleText = '';
        let lineCount = 0;

        for (let textLine of textObject.textArr) {
            let textString = visibleText.length > 0 ? ' ' + textLine.text : textLine.text;

            if (lineCount === maxLines) {
                let i = 1;
                while (textObject.measureSize(visibleText).width < width - 10) {
                    visibleText = visibleText + textString.slice(0, i);
                    i++;
                }
                textString = '';
            }

            visibleText = visibleText + textString;

            if (lineCount === maxLines) {
                break;
            }
            ++lineCount;
        }

        return visibleText;
    }

    function getTextSize(obj) {
        let initObjectGroup = obj.findAncestor('Group');

        // Create a blank canvas (by not filling a background color).
        var container = document.createElement('div');
        container.setAttribute("id", "measuringCanvas");
        container.width = obj.width();
        container.height = obj.height();

        document.body.insertBefore(container, document.getElementById("root"));

        let stage = new Konva.Stage({
            container: 'measuringCanvas',
            width: obj.width(),
            height: obj.height()
        });
        let layer = new Konva.Layer();

        layer.add(obj);

        stage.add(layer);

        stage.draw();

        let canvas = stage.toCanvas();

// Remove the surrounding transparent pixels
// result is an actual canvas element

        let ctx = canvas.getContext('2d', {willReadFrequently: true}),

            // create a temporary canvas in which we will draw back the trimmed text
            copy_canvas = document.createElement('canvas'),
            copy = copy_canvas.getContext('2d'),

            // Use the Canvas Image Data API, in order to get all the
            // underlying pixels data of that canvas. This will basically
            // return an array (Uint8ClampedArray) containing the data in the
            // RGBA order. Every 4 items represent one pixel.
            pixels = ctx.getImageData(0, 0, canvas.width, canvas.height),

            // total pixels
            l = pixels.data.length,

            // main loop counter and pixels coordinates
            i, x, y,

            // an object that will store the area that isn't transparent
            bound = {top: null, left: null, right: null, bottom: null};

        // for every pixel in there
        for (i = 0; i < l; i += 4) {

            // if the alpha value isn't ZERO (transparent pixel)
            if (pixels.data[i + 3] !== 0) {

                // find it's coordinates
                x = (i / 4) % canvas.width;
                y = ~~((i / 4) / canvas.width);

                // store/update those coordinates
                // inside our bounding box Object

                if (bound.top === null) {
                    bound.top = y;
                }

                if (bound.left === null) {
                    bound.left = x;
                } else if (x < bound.left) {
                    bound.left = x;
                }

                if (bound.right === null) {
                    bound.right = x;
                } else if (bound.right < x) {
                    bound.right = x;
                }

                if (bound.bottom === null) {
                    bound.bottom = y;
                } else if (bound.bottom < y) {
                    bound.bottom = y;
                }
            }
        }

        // actual height and width of the text
        // (the zone that is actually filled with pixels)
        var trimHeight = bound.bottom - bound.top,
            trimWidth = bound.right - bound.left;

        // get the zone (trimWidth x trimHeight) as an ImageData
        // (Uint8ClampedArray of pixels) from our canvas

        if (trimWidth && trimHeight) {
            var trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

            // Draw back the ImageData into the canvas
            copy.canvas.width = trimWidth;
            copy.canvas.height = trimHeight;
            copy.putImageData(trimmed, 0, 0);

            const textHeight = copy.canvas.height;
            const textWidth = copy.canvas.width;
            copy_canvas.remove();
            canvas.remove();
            container.remove();
            initObjectGroup.add(obj);
            stage.destroy()
            return {
                width: textWidth,
                height: textHeight,
                x: bound.left,
                y: bound.top
            }
        } else {
            copy_canvas.remove();
            canvas.remove();
            container.remove();
            initObjectGroup.add(obj);
            stage.destroy()
            return {
                width: trimWidth,
                height: trimHeight,
                x: bound.left,
                y: bound.top
            }
        }

    }

    function checkText(visible_text, textObj) {
        let brokenWords = hasBrokenWords(textObj.getAttr('text'), textObj.textArr);
        let txtSize = getTextSize(textObj);
        return !brokenWords &&
            textObj.textArr.length <= maxLines &&
            txtSize.height <= textObj.height() &&
            txtSize.width <= textObj.width();
    }

    function hasBrokenWords(sourceTokens, renderLines) {
        let combined = collectVisibleText(renderLines);

        let a = sourceTokens;
        let b = combined;

        if (a.length !== b.length) {
            return true;
        }

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return true;
            }
        }

        return false;
    }

    function collectVisibleText(textArr) {
        let combined_text = '';
        for (let i = 0; i < textArr.length; i++) {
            combined_text += (i === 0 || textArr[i-1].text.endsWith('-') || textArr[i].text.startsWith('-') ? "" : " ") + textArr[i].text;
        }
        return combined_text
    }
}

export const scaleShape = (shape) => {
    let scaleX = shape.parent.scaleX();
    let scaleY = shape.parent.scaleY();

    const width = shape.width() * scaleX;
    const height = shape.height() * scaleY;

    if (!isNaN(width) && width > 0) {
        shape.width(width);
    }

    if (!isNaN(height) && height > 0) {
        shape.height(height);
    }

    shape.parent.scale({x: 1, y: 1});
}

export const scaleEllipse = (shape) => {
    let scaleX = shape.parent.scaleX();
    let scaleY = shape.parent.scaleY();

    const width = shape.width() * scaleX;
    const height = shape.height() * scaleY;

    if (!isNaN(width) && width > 0) {
        shape.width(width);
    }

    if (!isNaN(height) && height > 0) {
        shape.height(height);
    }

    shape.setAttr('offset', {x: -width / 2, y: -height / 2});

    shape.parent.scale({x: 1, y: 1});
}

const createRegex = (value) => {
    try {
        return new RegExp(value);
    } catch (e) {
        return undefined;
    }
}

export const scaleRect = (rectGroup) => {
    const scale = rectGroup.scale();
    const rect = rectGroup.findOne('.background');

    if (!rect) return;

    rect.size({
        width: rect.width() * scale.x,
        height: rect.height() * scale.y,
    });

    rectGroup.scale({
        x: 1,
        y: 1,
    });
};
